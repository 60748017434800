import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import 'assets/css/global.css';
import HomeSection from './PageRoute';
import ProductSection from './ProductRoute';
import SupportSection from 'components/HomePage/SupportSection/SupportSection';
import ScrollToTop from 'components/Common/ScrollToTop/ScrollToTop';
import NotFound from 'components/HomePage/NotFound/NotFound';
import DisclosureSection from './DisclosureRoute';
const AppRoute = () => {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<HomeSection />}></Route>
				<Route path="/product" element={<ProductSection />}></Route>
				<Route path="/support" element={<SupportSection />}></Route>
				<Route path="/disclosure" element={<DisclosureSection />}></Route>
				<Route path="*" element={<NotFound />}></Route>
			</Routes>
		</>
	);
};

export default AppRoute;
