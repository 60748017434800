import React from 'react';
import Intro from '../Header/Intro/Intro';
import Navbar from '../Header/Navbar/Navbar';
import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<>
			<Intro isWhiteHeader />
			<section className="vh-100">
				<div className="d-flex gap-4 flex-column justify-content-center align-items-center gx-0 vh-100 text-center">
					<div>
						<img
							src={toAbsoluteUrl('/media/images/NotFound/not-found.svg')}
							alt=""
							className="img-fluid"
						/>
					</div>
					<div className="rw_reg fs-40 color3">Page Not Found</div>
					<div>
						<button
							className="fs-16 mr_reg bg-color1 px-4 py-2 border-0 colorWhite rounded-1 mt-2"
							onClick={() => {
								navigate('/');
							}}>
							Back To Home
						</button>
					</div>
				</div>
			</section>
			<Navbar />
		</>
	);
};

export default NotFound;
