import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import React from 'react';
import './style.css';
const RiskCategory = () => {
	return (
		<>
			<section id="riskCategory">
				<div className="risk-category p-lg-5 p-3">
					<div className="rw_reg fs-32 color32 portfolio-child-title">
						Impluse Alpha is best suited for investors looking for capital appreciation and is
						comfortable with higher levels of volatility and market fluctuations.
					</div>
					<div className="overflow-x-auto mt-3">
						<table className="risk-category-table w-100 border">
							<tr>
								<th>Minimum Amount</th>
								<th>No. Of Stocks</th>
								<th>Recommended Time</th>
								<th>Cap Exposure</th>
							</tr>
							<tr>
								<td>5,00,000</td>
								<td>15 to 20</td>
								<td>2-3 Years</td>
								<td>Large, Mid & Small Cap</td>
							</tr>
						</table>
					</div>
					<div className="d-flex justify-content-center my-5">
						<img
							src={toAbsoluteUrl('/media/images/Portfolio/portfolio-1.svg')}
							alt=""
							className="img-fluid"
						/>
					</div>
				</div>
			</section>
		</>
	);
};

export default RiskCategory;
