import React, { useState } from 'react';
import './Complaint.css';
import Select, { SingleValue, StylesConfig } from 'react-select';

type OptionType = {
	value: string;
	label: string;
};

type TableData = {
	isHeading: boolean;
	firstColumnContent: string;
	secondColumnContent: string;
	thirdColumnContent: string;
	fourthColumnContent: string;
	fifthColumnContent: string;
	sixColumnContent?: string;
	sevenColumnContent?: string;
};

const RowTypeRenderer = ({
	content,
	isHeading,
	RowType,
}: {
	content: string;
	isHeading: boolean;
	RowType: any;
}) => {
	return (
		<RowType
			className={`p-3 fs-14 text-nowrap ${isHeading ? 'disclosure-bg mr_semi' : 'fs-14 mr_reg'}`}>
			{content}
		</RowType>
	);
};

const Complaint = () => {
	const currentDate = new Date();
	// currentDate.setMonth(currentDate.getMonth() - 1);
	const year = currentDate.getFullYear();
	const month = currentDate.toLocaleString('default', { month: 'long' });

	const complaintOptions: OptionType[] = [
		{ value: `currentMonthYear`, label: `${month} - ${year}` },
		{ value: 'monthly', label: 'Monthly' },
	];

	// Use the updated TableData type
	const currentMonthData: TableData[] = [
		{
			firstColumnContent: 'Received From',
			secondColumnContent: 'Pending Last Month',
			thirdColumnContent: 'Pending > 3M',
			fourthColumnContent: 'Total Pending',
			fifthColumnContent: 'Received',
			sixColumnContent: 'Resolved',
			sevenColumnContent: 'Avg Resolution Time',
			isHeading: true,
		},
		{
			firstColumnContent: 'Investors',
			secondColumnContent: '-',
			thirdColumnContent: '-',
			fourthColumnContent: '0',
			fifthColumnContent: '0',
			sixColumnContent: '0',
			sevenColumnContent: '-',
			isHeading: false,
		},
		{
			firstColumnContent: 'SEBI Scores',
			secondColumnContent: '-',
			thirdColumnContent: '-',
			fourthColumnContent: '0',
			fifthColumnContent: '0',
			sixColumnContent: '0',
			sevenColumnContent: '-',
			isHeading: false,
		},
		{
			firstColumnContent: 'Other Sources',
			secondColumnContent: '-',
			thirdColumnContent: '-',
			fourthColumnContent: '0',
			fifthColumnContent: '0',
			sixColumnContent: '0',
			sevenColumnContent: '-',
			isHeading: false,
		},
		{
			firstColumnContent: 'Grand Total',
			secondColumnContent: '-',
			thirdColumnContent: '-',
			fourthColumnContent: '0',
			fifthColumnContent: '0',
			sixColumnContent: '0',
			sevenColumnContent: '-',
			isHeading: false,
		},
	];

	const monthlyData: TableData[] = [
		{
			firstColumnContent: 'Month',
			secondColumnContent: 'Carried forward',
			thirdColumnContent: 'Received',
			fourthColumnContent: 'Resolved',
			fifthColumnContent: 'Pending',
			isHeading: true,
		},
		{
			firstColumnContent: 'Oct - 24',
			secondColumnContent: '0',
			thirdColumnContent: '0',
			fourthColumnContent: '0',
			fifthColumnContent: '0',
			isHeading: false,
		},
		// {
		// 	firstColumnContent: 'Nov - 23',
		// 	secondColumnContent: '0',
		// 	thirdColumnContent: '0',
		// 	fourthColumnContent: '0',
		// 	fifthColumnContent: '0',
		// 	isHeading: false,
		// },
		// {
		// 	firstColumnContent: 'Dec - 23',
		// 	secondColumnContent: '0',
		// 	thirdColumnContent: '0',
		// 	fourthColumnContent: '0',
		// 	fifthColumnContent: '0',
		// 	isHeading: false,
		// },
		{
			firstColumnContent: 'Grand Total',
			secondColumnContent: '0',
			thirdColumnContent: '0',
			fourthColumnContent: '0',
			fifthColumnContent: '0',
			isHeading: false,
		},
	];
	function addCurrentMonthData() {
		const today = new Date();
		const monthNames = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		const month = monthNames[today.getMonth()];
		const year = today.getFullYear() % 100;

		const currentMonthLabel = `${month} - ${year}`;

		const lastDataIndex = monthlyData.length - 2;
		const lastMonthLabel = monthlyData[lastDataIndex]?.firstColumnContent;

		if (lastMonthLabel !== currentMonthLabel) {
			monthlyData.splice(lastDataIndex + 1, 0, {
				firstColumnContent: currentMonthLabel,
				secondColumnContent: '0',
				thirdColumnContent: '0',
				fourthColumnContent: '0',
				fifthColumnContent: '0',
				isHeading: false,
			});
		}
	}

	addCurrentMonthData();

	const [selectedOption, setSelectedOption] = useState<string>(complaintOptions[0].value);

	const handleSelectChange = (selected: SingleValue<OptionType>) => {
		if (selected !== null) {
			setSelectedOption(selected.value);
		}
	};

	const renderTable = () => {
		const tableDetails = selectedOption === 'currentMonthYear' ? currentMonthData : monthlyData;

		return (
			<table className="table align-middle m-0 table-content">
				<tbody>
					{tableDetails.map((tableContent, index) => {
						const RowType = tableContent.isHeading ? 'th' : 'td';
						return (
							<tr key={index}>
								<RowTypeRenderer
									RowType={RowType}
									content={tableContent.firstColumnContent}
									isHeading={tableContent.isHeading}
								/>
								<RowTypeRenderer
									RowType={RowType}
									content={tableContent.secondColumnContent}
									isHeading={tableContent.isHeading}
								/>
								<RowTypeRenderer
									RowType={RowType}
									content={tableContent.thirdColumnContent}
									isHeading={tableContent.isHeading}
								/>
								<RowTypeRenderer
									RowType={RowType}
									content={tableContent.fourthColumnContent}
									isHeading={tableContent.isHeading}
								/>
								<RowTypeRenderer
									RowType={RowType}
									content={tableContent.fifthColumnContent}
									isHeading={tableContent.isHeading}
								/>
								{tableContent.sixColumnContent && (
									<RowTypeRenderer
										RowType={RowType}
										content={tableContent.sixColumnContent}
										isHeading={tableContent.isHeading}
									/>
								)}
								{tableContent.sevenColumnContent && (
									<RowTypeRenderer
										RowType={RowType}
										content={tableContent.sevenColumnContent}
										isHeading={tableContent.isHeading}
									/>
								)}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};
	const customStyles: StylesConfig<OptionType, false> = {
		control: (provided, state) => ({
			...provided,
			color: 'black',
			borderColor: state.isFocused ? 'black' : provided.borderColor,
			boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
			'&:hover': {
				borderColor: 'black',
			},
		}),
		singleValue: (provided) => ({
			...provided,
			color: 'black',
			padding: '8px',
			width: '190px',
		}),
		option: (provided, state) => ({
			...provided,
			color: 'black',
			backgroundColor: 'white',
			'&:hover': {
				backgroundColor: '#2d8287',
				color: 'white',
			},
		}),
	};
	return (
		<>
			<section>
				<div className="container">
					<div className="row">
						<div className="col pt-5">
							<div className="row">
								<div className="col py-lg-5">
									<div className="align-content-around">
										<div className="color6 fs-20 mr_reg">Disposal of complaints</div>
										<div className="row align-items-center">
											<div className="col-md-8">
												<div className="color3 fs-48 rw_reg lh-sm my-2 about-title">
													Complaint Status
												</div>
											</div>
											<div className="col-md-4 ">
												<div className="d-md-flex justify-content-end mb-3">
													<Select
														className="complaintOptions"
														defaultValue={complaintOptions[0]}
														options={complaintOptions}
														onChange={handleSelectChange}
														styles={customStyles}
														isSearchable={false}
													/>
												</div>
											</div>
											<div className="table-responsive niveshhay-table-responsive">
												<div className="complaint-table-container">{renderTable()}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Complaint;
