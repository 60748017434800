import React from 'react';
// @ts-ignore
import Slider from 'react-slick';
import TeamCard from 'components/Common/TeamCard/TeamCard';
import './style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';

const Team = () => {
	const teamCard = [
		{
			img: toAbsoluteUrl('/media/images/Team/team-7.png'),
			name: 'Bhavdeep Shah',
			profession: 'Founder & Director',
			description:
				"With more than 15 years of entrepreneurial expertise, during which he has overseen various company functions, spearheaded growth initiatives, and cultivated a robust talent pool. He holds a Bachelor's degree in Commerce from the University of Mumbai and harbors a deep passion for the realm of investment.",
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-8.svg'),
			name: 'Nirav Kansariwala',
			profession: 'Director',
			description:
				'A financial expert with a decade of experience, he served as AVP at Jainam Broking Ltd. He holds a BE and PGDM in Finance from ITM Business School, and is NISM Certified Investment Advisor. Specializing in fund, portfolio, and security management, he consistently optimizes returns while minimizing risks.',
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-1.svg'),
			name: 'Jay Patel',
			profession: 'Technical Analyst',
			description:
				'Working in capital markets since 2010 as a technical analyst and managing a family portfolio. Passionate about investing and teaching, he’s shared  his technical analysis expertise for four years. A B.E. graduate with an MBA in Financial Markets, CFTe, and CMT level 3, he is dedicated to continuous learning.',
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-3.svg'),
			name: 'Mahima Sethiya',
			profession: 'Equity Research Analyst',
			description:
				"With an MBA in Finance from Banasthali University, I bring 2.5 years of equity research experience, focusing on small and mid-cap stocks. Her sector expertise includes Tyres, Plastic Pipes, and related industries. I'm also an active NSS volunteer, committed to community service and personal growth.",
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-2.svg'),
			name: 'Raj Patel',
			profession: 'Research Analyst',
			description:
				'Raj Patel is a research analyst at Titiksha Wealth with two years of equity research experience. A CFA Level 2 candidate and online MBA (Finance) student, he is dedicated to growing his financial expertise. He brings a strong analytical mindset and passion for markets, offering valuable insights.',
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-4.png'),
			name: 'Raghav Maheshwari',
			profession: 'Research Analyst',
			description:
				'Raghav Maheshwari is a  Chartered Accountant by qualification and currently pursuing his CFA certification. His keen interest in capital markets brought him to Titiksha Wealth. In addition to his expertise in the financial realm, he is also an avid practitioner of meditation.',
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-6.svg'),
			name: 'Henil Shah',
			profession: 'Equity Research Analyst',
			description:
				'A Research Analyst at Titiksha Wealth Pvt Ltd, he brings extensive experience in capital market research, covering equity, debt, and mutual funds. With a Master’s in Management Studies and Finance, he leverages his interpersonal skills to offer valuable insights, analytical expertise, and dedicated support to the team.',
		},
		{
			img: toAbsoluteUrl('/media/images/Team/team-5.svg'),
			name: 'Kalpit Doshi',
			profession: 'Research Analyst',
			description:
				'An Equity Research Analyst at Titiksha Wealth, Kalpit specializes in fundamental stock market and company analysis. He holds a B.Com and is completing his CA finals. Kalpit blends his analytical skills with a passion for diverse reading, enhancing his insights into financial markets.',
		},
	];

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 2000,
		pauseOnHover: true,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			<section id="teamSection" className="bg-liner-v-color8">
				<div className="container">
					<div className="row">
						<div className="col px-0 px-md-5 py-lg-5">
							<div className="">
								<div className="py-5 text-center colorWhite">
									<div className="fs-16 pt-4 mr_reg">Our Team</div>
									<div className="fs-48 pb-5 rw_reg team-subtitle">Meet Your Financial Wizards</div>
								</div>
								<div>
									<Slider {...settings} className="mx-5 mx-lg-0 gap-3">
										{teamCard.map((card, index) => (
											<div key={index}>
												<TeamCard
													key={index}
													img={card.img}
													name={card.name}
													profession={card.profession}
													description={card.description}
												/>
											</div>
										))}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Team;
