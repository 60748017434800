document.addEventListener("DOMContentLoaded", () => {
  const sections = document.querySelectorAll("section");
  const navItems = document.querySelectorAll(".nav-item");
  const options = {
    threshold: 0.7,
  };

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        navItems.forEach((item) => {
          item.classList.remove("active");
        });
        const activeNav = document.querySelector(
          `[href="#${entry.target.id}"]`
        ).parentElement;
        activeNav.classList.add("active");
      }
    });
  }, options);

  sections.forEach((section) => {
    observer.observe(section);
  });
});
