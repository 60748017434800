import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Intro from '../Header/Intro/Intro';
import { Bounce, Slide, toast, ToastContainer } from 'react-toastify';
import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import './SupportSection.css';
import Footer from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

interface FormData {
	fullName: string;
	email: string;
	phoneNumber: string;
	message: string;
}

const SupportSection = () => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isSubmitting },
		reset,
	} = useForm<FormData>();
	const [locationLoading, setLocationLoading] = useState(true);

	const queryParams = new URLSearchParams(location.search);
	const emailId = queryParams.get('emailId');

	useEffect(() => {
		if (emailId) {
			setValue('email', emailId);
		}
	}, [emailId]);

	const onSubmit = async (formData: FormData) => {
		const data = new FormData();
		data.append('fullName', formData.fullName);
		data.append('email', formData.email);
		data.append('phoneNumber', formData.phoneNumber);
		data.append('message', formData.message);
		const Sheet_Url =
			'https://script.google.com/macros/s/AKfycbymZn8r3d5Wc_t2xAP1tQggGf3XyTkST0o2RNxpaz_FUxxXDWIL-RlNs3Nq9EQ2MUQF/exec';

		try {
			await fetch(Sheet_Url, {
				method: 'POST',
				body: data,
			});
			toast.success('Form submitted!');
			reset();
		} catch (error) {
			toast.error('Error submitting form. Please try again.');
			console.log(error);
		}
	};

	const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const cleanedPhoneNumber = event.target.value.replace(/\D/g, '').slice(0, 10);
		setValue('phoneNumber', cleanedPhoneNumber);
	};

	return (
		<>
			<section className="supportSectionForm">
				<Intro />
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover={false}
					theme="dark"
					transition={Slide}
					className="support-toast"
				/>
				<div className="container">
					<div className="row">
						<div className="col  py-5">
							<div className="row">
								<div className="col  py-lg-5">
									<div className="px-1 px-lg-0">
										<div className="row">
											<div className="my-lg-5 mt-5">
												<div className="color3 fs-48 rw_reg lh-sm my-2 about-title text-center support-title">
													Contact Us
												</div>
												<div className="color6 fs-20 mr_reg text-center support-desc">
													We will get back to you as soon as we can.
												</div>
											</div>
											<div className="col-lg-8 mx-auto">
												<form onSubmit={handleSubmit(onSubmit)}>
													<div className="row">
														<div className="form-group my-4">
															<input
																type="text"
																className="form-control mr_reg form_control"
																id="fullName"
																placeholder="Full Name"
																{...register('fullName', { required: 'Full Name is required' })}
															/>
															{errors.fullName && (
																<span className="text-danger">{errors.fullName.message}</span>
															)}
														</div>
													</div>
													<div className="row">
														<div className="form-group my-4 col-lg-6">
															<input
																type="email"
																className="form-control mr_reg form_control"
																id="email"
																placeholder="Email Address"
																{...register('email', {
																	required: 'Email is required',
																	pattern: {
																		value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
																		message: 'Invalid email address',
																	},
																})}
															/>
															{errors.email && (
																<span className="text-danger">{errors.email.message}</span>
															)}
														</div>
														<div className="form-group my-4 col-lg-6">
															<input
																type="tel"
																className="form-control mr_reg form_control"
																id="phoneNumber"
																placeholder="Phone Number"
																maxLength={10}
																{...register('phoneNumber', {
																	required: 'Phone number is required',
																	pattern: {
																		value: /^\d{10}$/,
																		message: 'Phone number must be 10 digits',
																	},
																})}
																onChange={handlePhoneNumberChange}
															/>
															{errors.phoneNumber && (
																<span className="text-danger">{errors.phoneNumber.message}</span>
															)}
														</div>
													</div>
													<div className="row">
														<div className="form-group my-4">
															<textarea
																className="form-control Textarea_control mr_reg"
																id="message"
																rows={4}
																placeholder="Your Message"
																{...register('message', {
																	required: 'Message is required',
																})}></textarea>
															{errors.message && (
																<span className="text-danger">{errors.message.message}</span>
															)}
														</div>
													</div>
													<button
														type="submit"
														className="btn sup-btn bg-color1 text-light px-4 rounded-1 mr_reg fs-16"
														disabled={isSubmitting}>
														{isSubmitting ? 'Sending...' : 'Send'}
													</button>
												</form>
											</div>
											<div className="col-lg-8 mx-auto">
												<div className="mt-lg-5 pt-5 pb-5 pb-lg-0 rounded-start rounded-none">
													<div className="row gap-md-5 gap-3">
														<div className="text-black fs-48 rw_reg lh-sm my-2 support-title">
															Where to find us
														</div>
														<div className="d-flex">
															<div className="d-flex mt-1 ">
																<img
																	src={toAbsoluteUrl('/media/images/Support/mail-1.svg')}
																	alt=""
																	className="me-3"
																	width={30}
																	height={30}
																/>
															</div>
															<div>
																<div className="fs-24 color3 mr_reg support-sec-text">Email Id</div>
																<div className="fs-20 color6 mr_reg support-sec-desc">
																	titikshawealth@gmail.com
																</div>
															</div>
														</div>
														<div className="d-flex">
															<div className="d-flex mt-1 ">
																<img
																	src={toAbsoluteUrl('/media/images/Support/call.svg')}
																	alt=""
																	className="me-3"
																	width={30}
																	height={30}
																/>
															</div>
															<div>
																<div className="fs-24 color3 mr_reg support-sec-text">
																	Mobile Number
																</div>
																<div className="fs-20 color6 mr_reg support-sec-desc">
																	+91 93270 60751
																</div>
															</div>
														</div>
														<div className="d-flex">
															<div className="d-flex mt-1 ">
																<img
																	src={toAbsoluteUrl('/media/images/Support/location.svg')}
																	alt=""
																	className="me-3"
																	width={30}
																	height={30}
																/>
															</div>
															<div>
																<div className="fs-24 color3 mr_reg support-sec-text">
																	Corporate Office Address
																</div>
																<div className="fs-20 color6 mr_reg support-sec-desc">
																	302, Raj Laxmi Appt, Maskati Plots, Beside Dhiraj Sons, Parle
																	Point, Surat.
																</div>
															</div>
														</div>{' '}
														<div className="d-flex">
															<div className="d-flex mt-1 ">
																<img
																	src={toAbsoluteUrl('/media/images/Support/location.svg')}
																	alt=""
																	className="me-3"
																	width={30}
																	height={30}
																/>
															</div>
															<div>
																<div className="fs-24 color3 mr_reg support-sec-text">
																	{' '}
																	Registered Address
																</div>
																<div className="fs-20 color6 mr_reg support-sec-desc">
																	2, Nehru Nagar Society , Dumas Road , Surat 395007
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* Map and Contact Info */}
											{/* <div className="col-12 mt-5">
												<div className="mt-5 row">
													<div className="col-lg-5">
														<div className="px-lg-4 pb-5 pb-lg-0 rounded-start rounded-none">
															<div className="row gap-md-5 gap-3">
																<div className="text-black fs-48 rw_reg lh-sm my-2 support-title">
																	Where to find us
																</div>
																<div className="d-flex">
																	<div className="d-flex align-items-center">
																		<img
																			src={toAbsoluteUrl('/media/images/Support/mail-1.svg')}
																			alt=""
																			className="me-3"
																			width={30}
																			height={30}
																		/>
																	</div>
																	<div>
																		<div className="fs-24 color3 mr_reg support-sec-text">
																			Email Id
																		</div>
																		<div className="fs-20 color6 mr_reg support-sec-desc">
																			titikshawealth@gmail.com
																		</div>
																	</div>
																</div>
																<div className="d-flex">
																	<div className="d-flex align-items-center">
																		<img
																			src={toAbsoluteUrl('/media/images/Support/call.svg')}
																			alt=""
																			className="me-3"
																			width={30}
																			height={30}
																		/>
																	</div>
																	<div>
																		<div className="fs-24 color3 mr_reg support-sec-text">
																			Mobile Number
																		</div>
																		<div className="fs-20 color6 mr_reg support-sec-desc">
																			+91 93270 60751
																		</div>
																	</div>
																</div>
																<div className="d-flex">
																	<div className="d-flex align-items-center">
																		<img
																			src={toAbsoluteUrl('/media/images/Support/location.svg')}
																			alt=""
																			className="me-3"
																			width={30}
																			height={30}
																		/>
																	</div>
																	<div>
																		<div className="fs-24 color3 mr_reg support-sec-text">
																			Location
																		</div>
																		<div className="fs-20 color6 mr_reg support-sec-desc">
																			302, Raj Laxmi Appt, Maskati Plots, Beside Dhiraj Sons, Parle
																			Point, Surat.
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-7">
														{locationLoading && (
															<div className="d-flex justify-content-center align-items-center w-100 h-100">
																<div className="spinner-border" role="status"></div>
															</div>
														)}
														<iframe
															src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.715945255715!2d72.7944698!3d21.1751109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04defd54eafc5%3A0xafecb5a44faec1bd!2sDhiraj%20Sons%20Super%20Market!5e0!3m2!1sen!2sin!4v1727504590549!5m2!1sen!2sin"
															width="600"
															height="450"
															style={{ border: 0 }}
															allowFullScreen
															loading="lazy"
															className="w-100"
															referrerPolicy="no-referrer-when-downgrade"
															onLoad={() => setLocationLoading(false)}></iframe>
													</div>
												</div>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</section>
		</>
	);
};

export default SupportSection;
