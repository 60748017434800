import React from "react";
import "./style.css"
interface ProductCardProps {
  number: number;
  title: string;
  text: string;
}
const ProductCard: React.FC<ProductCardProps> = ({ number, title, text }) => {
  return (
    <>
      <div className="col-md-6 px-lg-0 py-md-5">
        <div className="card border-0 text-center">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-2 col-3">
                <span className="fs-24 text-light bg-color1 p-2 px-3 rounded-circle ">
                  {number}
                </span>
              </div>
              <div className="col-lg-10 col-9 p-0">
                <h5 className="card-title rw_reg fs-24 color1 text-start m-0 product-card-title">{title}</h5>
                <p className="card-text fs-16 mr_med color6 text-start mt-3 pe-lg-5 pe-4">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ProductCard;
