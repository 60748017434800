import React, { useEffect, useState } from 'react';
import './style.css';
import RiskCategory from './RiskCategory/RiskCategory';
import Benchmarking from './Benchmarking/Benchmarking';
import YourPortfolio from './YourPortfolio/YourPortfolio';
import MomentumFramework from './MomentumFramework/MomentumFramework';

const Portfolio = () => {
	const navItems = ['Risk category', 'Your Portfolio', 'Momentum framework'];
	const [activeLink, setActiveLink] = useState(navItems[0]);
	useEffect(() => {
		const intervalTime = 18000;
		const interval = setInterval(() => {
			setActiveLink((prevActiveLink) => {
				const currentIndex = navItems.indexOf(prevActiveLink);
				const nextIndex = (currentIndex + 1) % navItems.length;
				return navItems[nextIndex];
			});
		}, intervalTime);

		return () => clearInterval(interval);
	}, [navItems]);

	const renderActiveComponent = () => {
		switch (activeLink) {
			case 'Risk category':
				return <RiskCategory />;
			case 'Benchmarking':
				// return <Benchmarking />;
			case 'Your Portfolio':
				return <YourPortfolio />;
			case 'Momentum framework':
				return <MomentumFramework />;
			default:
				return null;
		}
	};

	return (
		<div>
			<section id="PortfolioSection">
				<div className="container">
					<div className="row">
						<div className="col py-lg-5 py-4">
							<div className="row">
								<div className="col py-lg-5 py-4">
									<div className="px-1 px-lg-0">
										<div className="text-center pt-lg-0 pt-5">
											<div className="fs-48 pb-3 rw_reg color3 service-title">
												Building Your Portfolio
											</div>
											<div className="row">
												<div className="fs-18 mr_reg color6 mb-lg-0 mb-2 col-lg-9 mx-lg-auto">
													Impulse Alpha is our flagship portfolio and is designed on the back of our
													own proprietary strategy which derives its principles from momentum
													investing.
												</div>
											</div>
										</div>
										<div className="my-5">
											<ul className="nav nav-pills nav-fill">
												{navItems.map((item, index) => (
													<li
														className={`nav-item col-12 col-lg-3 ${index !== navItems.length - 1 ? 'me-4' : ''}`}
														key={index}>
														<span
															role="button"
															className={`nav-link portfolio-nav-link ${activeLink === item ? 'portfolio-active' : ''}`}
															onClick={() => setActiveLink(item)}>
															{item}
														</span>
														{index < navItems.length - 1 && <span className="me-4 me-lg-4"></span>}
													</li>
												))}
											</ul>
										</div>

										<div className="active-component">{renderActiveComponent()}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Portfolio;
