import React, { useState } from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import SupportForm from 'components/Common/SupportForm/SupportForm';
import './style.css';
import { useNavigate } from 'react-router-dom';
const Purpose = () => {
	const navigate = useNavigate();
	return (
		<>
			<section id="purposeSection">
				<div className="container">
					<div className="row">
						<div className="col  py-5">
							<div className="row">
								<div className="col  py-lg-5">
									<div className="px-1 px-lg-0">
										<div className="row reverse_col">
											<div className="col-lg-6">
												<div className="d-flex justify-content-lg-end justify-content-center my-lg-0 my-4">
													<img
														className="img-fluid"
														src={toAbsoluteUrl('/media/images/Purpose/purpose.svg')}
														alt=""
													/>
													{/* <img
                            className="mobile-img"
                            src={toAbsoluteUrl(
                              "/media/images/AboutSection/gujarat-map-mobile.svg"
                            )}
                            alt=""
                          /> */}
												</div>
											</div>
											<div className="align-content-around col-lg-6 pe-lg-0">
												<div className="color6 fs-16 mr_reg">Who are we?</div>
												<div className="color3 fs-48 rw_reg lh-sm my-2 about-title">
													A purpose-driven investment firm.
												</div>
												<div className="color3 fs-16 mr_reg my-3 mb-4 pe-lg-4">
													Titiksha Wealth is the wealth advisory firm dedicated to helping clients
													achieve their financial goals through strategic investments in equity
													stocks. At Titiksha Wealth, our approach is grounded in thorough market
													analysis (Technical and Fundamental), disciplined portfolio management,
													and a commitment to transparency.
												</div>
												<button
													className="fs-16 mr_reg bg-color30 px-3 py-2 border-1  color3 rounded-1 mt-lg-2 my-4"
													onClick={() => {
														navigate('/support');
													}}>
													Get In Touch
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Purpose;
