import React, { useState } from "react";
import { toAbsoluteUrl } from "assets/helpers/AssestHelpers";
import "./style.css";

interface TeamCardProps {
  img: string;
  name: string;
  profession: string;
  description: string
}

const TeamCard: React.FC<TeamCardProps> = ({ img, name, profession, description }) => {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <>
      <div className="d-flex justify-content-md-center">

        <div className={`col-lg-9 col-md-9`}>
          <div className={`border-0 card px-0 rounded-0 ${showDescription ? "show" : ""}`}>
            <img
              src={img}
              className="card-img-top rounded-0"
              alt="..."
            />
            <div className="card-body border">
              <h5 className="card-title fs-20 color3 mr_reg my-2">
                {name}
              </h5>
              <h6 className="card-subtitle mb-2 text-body-secondary color5 fs-16 mr_reg my-3">
                {profession}
              </h6>
              <p
                className={`card-text card_text fs-12 color5 mr_reg ${showDescription ? "show" : "hide"}`}
              >
                {description}
              </p>
              <div className="text-center">
                <button onClick={toggleDescription} className="btn btn-link p-0 team-card-shadow">
                  {showDescription ? (
                    <img
                      src={toAbsoluteUrl("/media/images/Team/arrow-up.svg")}
                      alt=""
                    />
                  ) : (
                    <img
                      src={toAbsoluteUrl("/media/images/Team/arrow-down.svg")}
                      alt=""
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
