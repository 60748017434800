import React, { useState, useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssestHelpers';
import { useLocation, useNavigate } from 'react-router-dom'; // import useLocation for path detection
import './style.css';

const Intro = ({ isWhiteHeader = false }: { isWhiteHeader?: boolean }) => {
	const [whiteHeader, setWhiteHeader] = useState(isWhiteHeader);
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		if ((location.pathname === '/product' || location.pathname === '/support') || isWhiteHeader) {
			setWhiteHeader(true);
		} else {
			const handleScroll = () => {
				const heroSection = document.getElementById('heroSection');
				const heroSectionHeight = heroSection?.offsetHeight || 0;
				const scrollPosition = window.scrollY;

				if (scrollPosition > heroSectionHeight) {
					setWhiteHeader(true);
				} else {
					setWhiteHeader(false);
				}
			};

			window.addEventListener('scroll', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [location.pathname]);

	// const handleClick = (e: any) => {
	// 	e.preventDefault();
	// 	const targetSection = document.querySelector(e.currentTarget.getAttribute('href'));

	// 	targetSection.scrollIntoView({ behavior: 'smooth' });
	// };

	return (
		<>
			<div
				className={`justify-content-center position-fixed w-100 d-lg-flex zindex ${
					location.pathname !== '/support' ? 'd-none' : ''
				} ${whiteHeader ? 'bg-white shadow-sm' : 'bg-blur'}`}
				role="button">
				<a
					href="#heroSection"
					onClick={() => {
						navigate('/');
					}}>
					<img
						src={toAbsoluteUrl(
							`/media/images/HeroSection/${whiteHeader ? 'logo.svg' : 'logo-white.svg'}`,
						)}
						alt="logo"
					/>
				</a>
			</div>
		</>
	);
};

export default Intro;
