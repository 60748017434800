import React from "react";
import { toAbsoluteUrl } from "../../../assets/helpers/AssestHelpers";
interface AboutCardProps {
  img: string;
  title: string;
  text: string;
}
const AboutCard: React.FC<AboutCardProps> = ({ img, title, text }) => {
  return (
    <>
      <div className="card col-lg-3 col-md-6 border-0">
        <div className="card-body p-0 text-start">
          <img src={img} className="my-4" alt="..." />
          <h5 className="card-title fs-24 rw_reg">{title}</h5>
          <p className="card-text fs-16 mr_reg">{text}</p>
        </div>
      </div>
    </>
  );
};

export default AboutCard;
