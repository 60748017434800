import React from "react";
import "./style.css";
import { toAbsoluteUrl } from "assets/helpers/AssestHelpers";
import ProductCard from "components/Common/ProductCard /ProductCard";
const ProductWork = () => {
  const cardData = [
    {
      number: 1,
      title: "Onboarding",
      text: "At Titiksha Wealth, our journey with you begins with a seamless onboarding process. We ensure all necessary steps are taken to make you comfortable and well-informed.",
    },
    {
      number: 2,
      title: "KYC",
      text: "As a critical step, we complete the Know Your Customer (KYC) process. This ensures compliance and helps us understand your needs better. Once completed, we provide ongoing advice via email and WhatsApp.",
    },
    {
      number: 3,
      title: "Investment",
      text: "You retain full control over your transactions. We offer guidance, and you make trades. We also provide regular stock rebalancing advice in the same manner, ensuring your portfolio remains optimized.",
    },
    {
      number: 4,
      title: "Rebalancing",
      text: "We also provide regular stock rebalancing advice in the same manner, ensuring your portfolio remains optimized.",
    },
  ];
  return (
    <>
      <section id="productWorkSection">
        <div className="container">
          <div className="row">
            <div className="col py-lg-5">
              <div className="row">
                <div className="col py-lg-5">
                  <div className="px-1 px-lg-0">
                    <div className="pt-5 text-center colorWhite">
                      {/* <div className="fs-16 mr_reg color6 mb-lg-0 mb-2">
                        Why Titiskha
                      </div> */}
                      <div className="fs-48 pb-5 rw_reg color3 service-title">
                        How It Works
                      </div>
                    </div>
                    <div className=" row  mx-lg-0 justify-content-lg-between justify-content-md-between gap-5 gap-lg-0 gap-md-0">
                      {cardData.map((card, index) => (
                        <ProductCard
                          key={index}
                          number={card.number}
                          title={card.title}
                          text={card.text}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductWork;
