import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import React from 'react';
import './ContactUs.css';
import { useNavigate } from 'react-router-dom';
const ContactUs = () => {
	const navigate = useNavigate();
	return (
		<>
			<section id="ContactUs" className="contact-us-bg mb-lg-5">
				<div className="container">
					<div className="row">
						<div className="col px-0 py-lg-5">
							<div className="px-3">
								<div className="py-5 text-center colorWhite">
									<div className="fs-48 rw_reg contactus-subtitle">Contact Us</div>
									<div className="fs-20 pt-4 mr_reg contactus-desc">
										Our whole team is here to support you: Our community. At Titiksha Wealth, our
										top priority is being a trustworthy source of info about Wealth Management & we
										are here to help.
									</div>
									<div>
										<button
											className="fs-18 bg-white color1 px-3 py-2 border-0 rounded-2 mr_med mt-5 mb-3"
											onClick={() => {
												navigate('/support');
											}}>
											<img
												src={toAbsoluteUrl('/media/images/Support/contactus.svg')}
												alt=""
												className="me-2"
											/>
											Get in Touch
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ContactUs;
