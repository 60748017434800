import React, { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssestHelpers';
import './style.css';
import SupportForm from '../../../Common/SupportForm/SupportForm';
import './script.js';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const Navbar = () => {
	const [activeImage, setActiveImage] = useState('hero');
	const [isNavbarOpen, setIsNavbarOpen] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const handleClick = (image: string) => {
		setActiveImage(image);
		const section = image + 'Section';
		console.log('🚀 ~ handleClick ~ section:', section);
		navigate('/#' + section);
	};

	const handleScroll = () => {
		if (location.pathname === '/product') return; // Skip scroll handling when on the /product page

		const scrollPosition = window.scrollY + window.innerHeight / 2;

		const sections = [
			{ id: 'heroSection', image: 'hero' },
			{ id: 'aboutSection', image: '' },
			{ id: 'mainProductSection', image: 'mainProduct' },
			{ id: 'purposeSection', image: '' },
			{ id: 'teamSection', image: 'team' },
			{ id: 'serviceSection', image: '' },
			{ id: 'testimonialsSection', image: '' },
			{ id: 'supportSection', image: 'support' },
			{ id: 'faqsSection', image: '' },
		];

		for (const section of sections) {
			const sectionElement = document.getElementById(section.id);
			if (sectionElement) {
				const rect = sectionElement.getBoundingClientRect();
				const offsetY = rect.top + window.scrollY;
				if (offsetY <= scrollPosition) {
					setActiveImage(section.image);
				}
			}
		}
	};

	// Handle location change and scroll events
	useEffect(() => {
		if (location.pathname === '/product' || location.pathname === '/disclosure') {
			setActiveImage(''); // Clear active image
		} else {
			window.addEventListener('scroll', handleScroll);
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [location]);

	return (
		<>
			<nav className="navbar navbar-expand-lg rounded-2 nav-position colorWhite nav-styling bg-color1 p-0">
				<div className="container-fluid nav-bg-md shadow">
					<div className="navbar-brand d-lg-none">
						<Link to="/#heroSection">
							<img src={toAbsoluteUrl('/media/images/HeroSection/logo-mobile.svg')} alt="" />
						</Link>
					</div>
					<button
						className={`navbar-toggler ${isNavbarOpen ? 'open' : ''}`}
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded={isNavbarOpen ? 'true' : 'false'}
						aria-label="Toggle navigation"
						onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse px-4 " id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4 mt-3">
							<li className="d-flex gap-2 justify-content-end nav-item">
								<Link
									to="/#heroSection"
									className={`${
										activeImage === 'hero' ? 'text-black' : 'color6'
									}  text-decoration-none d-lg-none fs-20`}
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										handleClick('hero');
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									Home
								</Link>
								<Link
									to="/#heroSection"
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show">
									<img
										className={`border-bottom pb-3 desktop-img home-img ${
											activeImage === 'hero' ? 'active' : ''
										}`}
										src={
											activeImage === 'hero'
												? toAbsoluteUrl('/media/images/Navbar/Hero-nav-icon-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/Hero-nav-icon.svg')
										}
										alt=""
										onClick={() => handleClick('hero')}
									/>

									<img
										className="border-bottom mobile-img"
										src={
											activeImage === 'hero'
												? toAbsoluteUrl('/media/images/Navbar/Hero-nav-icon-mobile-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/Hero-nav-icon-mobile.svg')
										}
										onClick={() => handleClick('hero')}
										alt=""
									/>
								</Link>
							</li>
							<li className="d-flex gap-2 justify-content-end nav-item">
								<Link
									to="/#mainProductSection"
									className={`${
										activeImage === 'mainProduct' ? 'text-black' : 'color6'
									}  text-decoration-none d-lg-none fs-20`}
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										handleClick('mainProduct');
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									Features
								</Link>
								<Link
									to="/#mainProductSection"
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									<img
										className={`border-bottom pb-3 desktop-img features-img ${
											activeImage === 'mainProduct' ? 'active' : ''
										}`}
										src={
											activeImage === 'mainProduct'
												? toAbsoluteUrl('/media/images/Navbar/about-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/about.svg')
										}
										alt=""
										onClick={() => handleClick('mainProduct')}
									/>

									<img
										className="border-bottom mobile-img"
										src={
											activeImage === 'mainProduct'
												? toAbsoluteUrl('/media/images/Navbar/features-mobile-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/features-mobile.svg')
										}
										alt=""
										onClick={() => handleClick('mainProduct')}
									/>
								</Link>
							</li>
							<li className="d-flex gap-2 justify-content-end nav-item">
								<Link
									to="/#teamSection"
									className={`${
										activeImage === 'team' ? 'text-black' : 'color6'
									}  text-decoration-none d-lg-none fs-20`}
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										handleClick('team');
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									Team
								</Link>
								<Link
									to="/#teamSection"
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									<img
										className={`border-bottom pb-3 desktop-img team-img ${
											activeImage === 'team' ? 'active' : ''
										}`}
										src={
											activeImage === 'team'
												? toAbsoluteUrl('/media/images/Navbar/team-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/team.svg')
										}
										alt=""
										onClick={() => handleClick('team')}
									/>

									<img
										className="border-bottom mobile-img"
										src={
											activeImage === 'team'
												? toAbsoluteUrl('/media/images/Navbar/team-mobile-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/team-mobile.svg')
										}
										alt=""
										onClick={() => handleClick('team')}
									/>
								</Link>
							</li>
							<li className="d-flex gap-2 justify-content-end nav-item">
								<Link
									to="/#supportSection"
									className={`${
										activeImage === 'support' ? 'text-black' : 'color6'
									}  text-decoration-none d-lg-none fs-20`}
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										handleClick('support');
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									Support
								</Link>
								<Link
									to="/#supportSection"
									data-bs-toggle="collapse"
									data-bs-target=".navbar-collapse.show"
									onClick={() => {
										setIsNavbarOpen(!isNavbarOpen);
									}}>
									<img
										className={`border-bottom pb-3 desktop-img testimonial-img ${
											activeImage === 'support' ? 'active' : ''
										}`}
										src={
											activeImage === 'support'
												? toAbsoluteUrl('/media/images/Navbar/testimonials-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/testimonials.svg')
										}
										alt=""
										onClick={() => handleClick('support')}
									/>

									<img
										className="border-bottom mobile-img"
										src={
											activeImage === 'support'
												? toAbsoluteUrl('/media/images/Navbar/testimonials-mobile-active.svg')
												: toAbsoluteUrl('/media/images/Navbar/testimonials-mobile.svg')
										}
										alt=""
										onClick={() => handleClick('support')}
									/>
								</Link>
							</li>
						</ul>
						<ul className="navbar-nav my-4 my-lg-0 gap-4">
							<li className="d-flex gap-2 justify-content-end p-2">
								<img
									className="border-bottom d-lg-none"
									src={toAbsoluteUrl('/media/images/Navbar/impulse-alpha.svg')}
									alt=""
								/>
								<a
									role="button"
									className={`text_color text-decoration-none fs-20 mr_bold ${
										location.pathname === '/product' ? 'active-impulse' : ''
									}`}
									aria-current="page"
									onClick={() => {
										navigate('/product');
									}}>
									Impulse α
								</a>
							</li>
							<li className="d-flex gap-2 justify-content-end p-2">
								<img
									className="border-bottom  d-lg-none"
									src={toAbsoluteUrl('/media/images/Navbar/whatsapp.svg')}
									alt=""
								/>
								<a
									className="text_color text-decoration-none fs-20 mr_bold"
									aria-current="page"
									href="https://api.whatsapp.com/send/?phone=9327060751&text&type=phone_number&app_absent=0">
									Whatsapp
								</a>
							</li>
							<li className="d-flex gap-2 justify-content-end p-2">
								<img
									className="border-bottom  d-lg-none"
									src={toAbsoluteUrl('/media/images/Navbar/support.svg')}
									alt=""
								/>
								<div
									className="text_color text-decoration-none fs-20 mr_bold"
									aria-current="page"
									onClick={() => {
										navigate('/support');
									}}
									role="button">
									Get in Touch
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
