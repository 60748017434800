import DisclosureSection from 'components/HomePage/DisclosureSection/DisclosureSection';
import Intro from 'components/HomePage/Header/Intro/Intro';
import React from 'react';

const DisclosureRoute = () => {
	return (
		<>
			<section className="disclosureSection">
				<Intro isWhiteHeader />
				<DisclosureSection />
			</section>
		</>
	);
};

export default DisclosureRoute;
