import Footer from 'components/HomePage/Footer/Footer';
import Intro from 'components/HomePage/Header/Intro/Intro';
import Navbar from 'components/HomePage/Header/Navbar/Navbar';
import FAQS from 'components/HomePage/HomeSection/FAQS/FAQS';
import ContactUs from 'components/HomePage/ProductSection/ContactUs/ContactUs';
import Portfolio from 'components/HomePage/ProductSection/Portfolio/Portfolio';
import ProductHero from 'components/HomePage/ProductSection/ProductHero/ProductHero';
import ProductWork from 'components/HomePage/ProductSection/ProductWork/ProductWork';
const ProductSection = () => {
	
	return (
		<div>
			<Intro />
			<ProductHero />
			<ProductWork />
			<Portfolio />
			<ContactUs />
			{/* <FAQS /> */}
			<Navbar />
			<Footer />
		</div>
	);
};

export default ProductSection;
