import React from 'react';
import { toAbsoluteUrl } from '../../../assets/helpers/AssestHelpers';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
	const navigate = useNavigate();
	const currentPath = window.location.pathname;

	const handleFooterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const emailInput = document.getElementById('footerEmail') as HTMLInputElement | null;

		if (emailInput) {
			const email = emailInput.value;
			navigate(`/support?emailId=${encodeURIComponent(email)}`);
		}
	};

	return (
		<>
			<section id="footerSection " className="mt-5 bg-color1">
				<div className="container">
					<div className="row">
						<div className="col ">
							<div className="row">
								<div className="col py-3 py-lg-0">
									<div className="py-lg-5">
										<div className="row align-items-center p-lg-0 p-3 gap-lg-0 gap-3">
											<div className="col-lg-1">
												<Link to="/#heroSection">
													<img
														src={toAbsoluteUrl('/media/images/Footer/footer-logo.svg')}
														alt=""
														className="img-fluid"
													/>
												</Link>
											</div>
											<div className="col-lg-7 p-0">
												<ul className="nav">
													<li className="nav-item">
														<Link className="fs-16 mr_reg nav-link text-light " to="/#heroSection">
															Home
														</Link>
													</li>
													<li className="nav-item">
														<Link className="fs-16 mr_reg nav-link text-light " to="/product">
															Impulse Alpha
														</Link>
													</li>{' '}
													<li className="nav-item">
														<Link className="fs-16 mr_reg nav-link text-light " to="/support">
															Contact Us
														</Link>
													</li>
													<li className="nav-item">
														<Link className="fs-16 mr_reg nav-link text-light " to="/disclosure">
															Disclosure
														</Link>
													</li>
													<li className="nav-item">
														<a
															className="fs-16 mr_reg nav-link text-light "
															target="_blank"
															href={toAbsoluteUrl('/media/PDF/investor-charter.pdf')}>
															Investor Charter
														</a>
													</li>
													<li className="nav-item">
														<a className="fs-16 mr_reg nav-link text-light" 	target="_blank"
															href={toAbsoluteUrl('/media/PDF/PrivacyPolicy.pdf')}>
															Privacy Policy
														</a>
													</li>
												</ul>
											</div>
											<div className="col-lg-4">
												<div className="d-flex justify-content-lg-end">
													{currentPath !== '/support' && (
														<form
															className="d-flex border rounded-1"
															role="search"
															onSubmit={handleFooterSubmit}>
															<img
																className="ps-2"
																src={toAbsoluteUrl('/media/images/Footer/envelope.svg')}
																alt=""
															/>
															<input
																className="p-2 fs-14 border-0 input-focus-outline bg-transparent text-light"
																type="search"
																placeholder="Enter your email"
																aria-label="Enter your email"
																id="footerEmail"
																required
															/>
															<button
																className=" p-2 btn-design bg-light fs-14 color1 text-nowrap"
																type="submit">
																Get in Touch
															</button>
														</form>
													)}
												</div>
											</div>
										</div>
										<hr className="text-light opacity-50 my-4" />
										<div className="d-lg-flex justify-content-between align-items-center px-2 px-lg-0">
											<div className="text-center text-lg-start">
												<div className="fs-16 mr_reg text-light lh-28 ">
													SEBI Registration No. : INA000019497 | BASL Membership ID: 2201
												</div>
												<div className="fs-16 mr_reg text-light lh-28 ">
													Disclaimer: Investment in Securities Market are subject to market risks.
													Read all related documents carefully before investing.
												</div>
												<div className="fs-16 mr_reg text-light lh-28 ">
													{' '}
													Titiksha Wealth © . All Rights Reserved. |{' '}
													<a className="fs-16 mr_reg text-light " href="https://smartodr.in/">
														https://smartodr.in/
													</a>{' '}
												</div>
											</div>
											<div className="my-4 d-flex gap-4 justify-content-center">
												<a href="https://api.whatsapp.com/send/?phone=9327060751&text&type=phone_number&app_absent=0">
													<img
														src={toAbsoluteUrl('/media/images/Footer/whatsapp-footer.svg')}
														alt=""
													/>
												</a>
												<a href="https://x.com/TitikshaWealth">
													<img
														src={toAbsoluteUrl('/media/images/Footer/twitter-footer.svg')}
														alt=""
													/>
												</a>
												<a href="https://www.linkedin.com/in/titiksha-wealth/?originalSubdomain=in">
													<img
														src={toAbsoluteUrl('/media/images/Footer/linkedin-footer1.svg')}
														alt=""
													/>
												</a>
											</div>
										</div>
										<hr className="text-light opacity-50 my-4" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Footer;
