import Intro from 'components/HomePage/Header/Intro/Intro';
import React from 'react';
import './DisclosureSection.css';
import Navbar from '../Header/Navbar/Navbar';
import Footer from '../Footer/Footer';
const DisclosureSection = () => {
	const tableDetails: {
		isHeading: boolean;
		firstColumnContent: string;
		secondColumnContent: string;
		thirdColumnContent: string;
	}[] = [
		{
			firstColumnContent: 'No.',
			isHeading: true,
			secondColumnContent: 'Information Type',
			thirdColumnContent: 'Details',
		},
		{
			firstColumnContent: '1',
			isHeading: false,
			secondColumnContent: 'Complete name of Investment Adviser as registered with SEBI',
			thirdColumnContent: 'Titiksha Wealth Private Limited',
		},
		{
			firstColumnContent: '2',
			isHeading: false,
			secondColumnContent: 'Type of Registration',
			thirdColumnContent: 'Non- Individual',
		},
		{
			firstColumnContent: '3',
			isHeading: false,
			secondColumnContent: 'Registration Number',
			thirdColumnContent: 'INA000019497',
		},
		{
			firstColumnContent: '4',
			isHeading: false,
			secondColumnContent: 'Registered Address with telephone number',
			thirdColumnContent: '2, Nehru Nagar Society, Dumas Road , Surat, GUJARAT- 395007',
		},
		{
			firstColumnContent: '5',
			isHeading: false,
			secondColumnContent:
				'Contact details of the Principal Officer – Name, Contact No, Email ID  ',
			thirdColumnContent: 'Nirav Kansariwala,  9714855700, titikshawealth2023@gmail.com',
		},
		{
			firstColumnContent: '6',
			isHeading: false,
			secondColumnContent: 'Corresponding SEBI regional/local office address, phone number, email',
			thirdColumnContent:
				'SEBI Bhavan, Western Regional Office, Panchvati 1 st Lane, Gulbai Tekra road, Ahmedabad- 380006, Gujarat, - Phone Number: 079-27467018-20 - sebiwro@sebi.gov.in',
		},
	];

	return (
		<>
			<section className="disclosureSection">
				<Intro isWhiteHeader />
				<div className="container">
					<div className="row">
						<div className="col py-5">
							<div className="row">
								<div className="col py-lg-5">
									<div className="my-5">
										<div className="color3 fs-48 rw_reg lh-sm my-2 about-title text-center support-title">
											Disclosure
										</div>
										<div className="color6 fs-20 mr_reg text-center support-desc">
											Certain disclosure with respect to SEBI (Investment Advisors) Regulation ,2013
											read with Circular No:  SEBI/HO/MIRSD/MIRSD- PoD1/P/CIR/2024/75 dated June 05,
											2024
										</div>
									</div>
									<div className="tbl-container overflow-x-auto">
										<table className="table align-middle m-0 table-content">
											<tbody>
												{tableDetails.map((tableContent) => {
													const RowType = tableContent.isHeading ? 'th' : 'td';
													return (
														<tr className="">
															<RowType
																className={`col-1 text-center p-3 ${
																	tableContent.isHeading ? 'disclosure-bg mr_semi' : 'fs-14 mr_reg'
																}`}>
																{tableContent.firstColumnContent}
															</RowType>
															<RowType
																className={`col-5 p-3 ${
																	tableContent.isHeading ? 'disclosure-bg mr_semi' : 'fs-14 mr_reg'
																}`}>
																{tableContent.secondColumnContent}
															</RowType>
															<RowType
																className={`col-6 p-3 ${
																	tableContent.isHeading ? 'disclosure-bg mr_semi' : 'fs-14 mr_reg'
																}`}>
																{tableContent.thirdColumnContent}
															</RowType>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
									<div className="mt-5">
										<ul>
											<li className="mr_med fs-24 disclosure-link-text">
												In case you are not satisfied with our response you can lodge your grievance
												with SEBI at{' '}
												<a
													className="color1 text-decoration-none lh-36"
													href="http://scores.sebi.gov.in">
													 http://scores.sebi.gov.in 
												</a>
												or you may also write to any of the offices of SEBI. For any queries,
												feedback or assistance, please contact SEBI office on Toll Free Helpline at
												1800 22 7575/ 1800 266 7575. SCORES may be accessed thorough SCORES mobile
												application as well, same can be downloaded from below link:
												<a
													className="color1 text-decoration-none lh-36 text-break"
													href="https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330">
													https://play.google.com/store/apps/details?id=com.ionicframework.sebi236330
												</a>
											</li>
											<li className="mr_med fs-24 disclosure-link-text mt-3">
												ODR Portal could be accessed, if unsatisfied with the response. Your
												attention is drawn to the SEBI circular no.
												SEBI/HO/MIRSD/MIRSD-PoD1/P/CIR/2024/75 dated June 05, 2024, on “Online
												Resolution of Disputes in the Indian Securities Market”. A common Online
												Dispute Resolution Portal (“ODR Portal”) which harnesses conciliation and
												online arbitration for resolution of disputes arising in the Indian
												Securities Market has been established. ODR Portal can be accessed via the
												following link -
												<a
													className="color1 text-decoration-none lh-36"
													href="https://smartodr.in/">
													https://smartodr.in/
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Navbar />
				<Footer />
			</section>
		</>
	);
};

export default DisclosureSection;
