import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import React from 'react';
import './style.css';
import StockData from './StockData';

const colors = [
	'#0B111C',
	'#1F2A37',
	'#374151',
	'#4B5563',
	'#6B7280',
	'#9CA3AF',
	'#D1D8E0',
	'#D1D5DB',
	'#E5E7EB',
	'#D1D5DB',
	'#F3F4F6',
	'#F9FAFB',
	'#F7F8FC',
];

const labels = [
	'Automobile Ancillaries',
	'Health Care',
	'Miscellaneous',
	'Inds. Gases & Fuels',
	'Commodity',
	'FMCG',
	'Telecom',
	'Diversified',
	'Realty',
	'Consumer Durables',
	'Trading',
	'Logistics',
	'Textile',
];
const YourPortfolio = () => {
	return (
		<>
			<section id="YourPortfolio">
				<div className="your-portfolio p-lg-5 p-3">
					<div className="rw_reg fs-32 color32 text-center portfolio-child-title">
						The portfolio will consist of 15-20 stocks from various industries to provide superior
						risk adjusted returns.
					</div>
					<div className="d-flex justify-content-center my-5">
						<img src={toAbsoluteUrl('/media/images/Portfolio/portfolio-3.svg')} alt="" className='img-fluid'/>
					</div>
					<div className="stock-data-wrapper">
						{colors.map((color, index) => (
							<StockData key={index} color={color} label={labels[index] || `Sector ${index + 1}`} />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default YourPortfolio;
