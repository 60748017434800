import { toAbsoluteUrl } from 'assets/helpers/AssestHelpers';
import React from 'react';
import './style.css';
const MomentumFramework = () => {
	return (
		<>
			<section id="MomentumFramework">
				<div className="momentum-framework p-lg-5 p-3">
					<div className="rw_reg fs-24 color32">
						Impulse Flexi Cap Portfolio is meant to be a momentum-based investing style which aims
						to capitalise the wealth creation opportunity in the Indian economy. It is a systematic
						approach that aims to remove emotional bias from investment decisions by relying on
						predefined rules.
					</div>
					<div className="d-flex justify-content-center my-5">
						<img src={toAbsoluteUrl('/media/images/Portfolio/portfolio-4.svg')} alt="" className='img-fluid'/>
					</div>
					<div className="rw_reg fs-24 color32">
						The key focus is on earnings growth of portfolio companies or change in investor
						perception resulting in re-rating, either of these two will result in momentum in price
						action, the primary objective will be to spot the momentum and ride the momentum using
						predefined rules
					</div>
				</div>
			</section>
		</>
	);
};

export default MomentumFramework;
