import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssestHelpers';
import SupportForm from '../../../Common/SupportForm/SupportForm';
import './style.css';
import { useNavigate } from 'react-router-dom';
const Support = () => {
	const navigate = useNavigate();
	return (
		<>
			<section id="supportSection">
				<div className="container">
					<div className="row">
						<div className="col my-5 mb-lg-5 px-lg-5">
							<div className="row">
								<div className="col px-xl-5">
									<div className="border rounded-5 mx-xl-5">
										<div className="d-flex flex-column flex-lg-row justify-content-lg-between">
											<div className="col-lg-5 bg-color1 rounded-5">
												<div className="align-items-center d-flex h-100 justify-content-center">
													<img
														className="my-5 my-lg-0 img-fluid"
														src={toAbsoluteUrl('/media/images/Support/support-logo.svg')}
														alt=""
													/>
												</div>
											</div>
											<div className="col-lg-7">
												<div className="p-3 p-md-5 ">
													<div className="fs-48 rw_bold color1 text-center text-lg-start support-text">
														Get In Touch
													</div>
													<div className="fs-20 color29 mr_reg text-center text-lg-start">
														Join us at Titiksha Wealth, where we nurture endurance and opportunity,
														empowering you to achieve your long-term financial goals with unwavering
														commitment and holistic advice.
													</div>
													<hr className="color1 dotted-line opacity-100 my-5" />
													<div className="row gap-4">
														<div className="row">
															<div className="fs-16 col-md-3 col-5 color29 mr_reg">Community:</div>
															<div className="col-md-9 col-7">
																<div className="row gap-md-0 gap-4">
																	<a
																		className="fs-16 col-md-4 color1 mr_semi pe-0 text-decoration-none"
																		href="https://api.whatsapp.com/send/?phone=9327060751&text&type=phone_number&app_absent=0">
																		WhatsApp{' '}
																		<img
																			src={toAbsoluteUrl(
																				'/media/images/Support/arrow-up-right.svg',
																			)}
																			alt=""
																		/>{' '}
																	</a>
																	<a
																		className="fs-16 col-md-4 color1 mr_semi pe-0 text-decoration-none"
																		href="https://x.com/TitikshaWealth">
																		Twitter{' '}
																		<img
																			src={toAbsoluteUrl(
																				'/media/images/Support/arrow-up-right.svg',
																			)}
																			alt=""
																		/>{' '}
																	</a>
																	<a
																		className="fs-16 col-md-4 color1 mr_semi pe-0 text-decoration-none"
																		href="https://www.linkedin.com/feed/">
																		Linkedin{' '}
																		<img
																			src={toAbsoluteUrl(
																				'/media/images/Support/arrow-up-right.svg',
																			)}
																			alt=""
																		/>{' '}
																	</a>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="fs-16 col-md-3 col-5 color29 mr_reg pe-0">
																Wanna talk?
															</div>
															<div className="col-md-9 col-7 pe-0">
																<div className="row gap-4 gap-md-0">
																	<div
																		className="fs-16 col-md-4 pe-0 color1 mr_semi"
																		onClick={() => {
																			navigate('/support');
																		}}
																		role="button">
																		Get Support
																		<img
																			className="ms-1"
																			src={toAbsoluteUrl('/media/images/Support/message.svg')}
																			alt=""
																		/>{' '}
																	</div>
																	<div className="fs-16 col-md-8 color1 mr_semi pe-0">
																		titiksha@gmail.com
																		<img
																			className="ms-1"
																			src={toAbsoluteUrl('/media/images/Support/mail.svg')}
																			alt=""
																		/>{' '}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Support;
