import React from 'react';
import './style.css';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssestHelpers';
import { useNavigate } from 'react-router-dom';
const ProductHero = () => {
	const navigate = useNavigate();
	return (
		<>
			<section id="productHeroSection" className="product-hero-section vh-100">
				<div className="container  h-100 ">
					<div className="row  h-100">
						<div className="col-12 ">
							<div className="row h-100 justify-content-center align-items-center ">
								<div className="col-lg-7">
									<div className="colorWhite text-center p-3 p-md-0">
										<div className="fs-96 rw_reg color1 lh-sm hero-title">Impulse Alpha</div>

										<div className="fs-16 mr_reg mt-4 color4">
											Participate in India’s growth story through investing in the Indian equity
											markets. Impulse Alpha is Designed to capture market trends and capitalize on
											momentum.
										</div>
										<div className="mt-4">
											<button
												className="fs-16 bg-color1 px-4 py-2 border-0 rounded-2 mr_med colorWhite"
												onClick={() => {
													navigate('/support');
												}}>
												Get in Touch
											</button>
										</div>
									</div>
								</div>
								<button
									className=" position-absolute bg-transparent border-0"
									style={{ bottom: '3rem' }}>
									<a href="#productWorkSection">
										<img
											src={toAbsoluteUrl('/media/images/HeroSection/arrow-down-round.svg')}
											alt="down-arrow"
										/>
									</a>
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ProductHero;
