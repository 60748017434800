import React, { useEffect } from 'react';
import Hero from 'components/HomePage/HomeSection/Hero/Hero';
import Intro from 'components/HomePage/Header/Intro/Intro';
import About from 'components/HomePage/HomeSection/About/About';
import Navbar from 'components/HomePage/Header/Navbar/Navbar';
import Team from 'components/HomePage/HomeSection/Team/Team';
import Service from 'components/HomePage/HomeSection/Services/Service';
// import Testimonials from 'components/HomePage/HomeSection/Testimonials/Testimonials';
import Support from 'components/HomePage/HomeSection/Support/Support';
import FAQS from 'components/HomePage/HomeSection/FAQS/FAQS';
import Footer from 'components/HomePage/Footer/Footer';
import Blog from 'components/HomePage/HomeSection/Blog/Blog';
import Purpose from 'components/HomePage/HomeSection/Purpose/Purpose';
import Product from 'components/HomePage/HomeSection/Product/Product';
import { useLocation } from 'react-router-dom';
import Complaint from 'components/HomePage/HomeSection/Complaint/Complaint';

const HomeSection = () => {
	const location = useLocation();

	useEffect(() => {
		const element = document.getElementById(location.hash.replace('#', ''));
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}, [location.hash]);

	return (
		<>
			<Intro />
			<Hero />
			<About />
			<Product />
			<Purpose />
			<Team />
			<Service />
			{/* <Testimonials /> */}
			<Support />
			{/* <Blog /> */}
			{/* <FAQS /> */}
			<Complaint />
			<Navbar />
			<Footer />
		</>
	);
};

export default HomeSection;
