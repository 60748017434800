import React from "react";
import "./style.css";
import { toAbsoluteUrl } from "../../../../assets/helpers/AssestHelpers";
const Hero = () => {
  const handleClick = (e: any) => {
    e.preventDefault();
    const targetSection = document.querySelector(
      e.currentTarget.getAttribute("href")
    );

    targetSection.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <section id="heroSection" className="hero-section vh-100">
        <div className="container  h-100 ">
          <div className="row  h-100">
            <div className="col-12 ">
              <div className="row h-100 justify-content-center align-items-center ">
                <div className="col-lg-9 ">
                  <div className="colorWhite text-center p-3 p-md-0">
                    <div className="fs-54 rw_reg lh-sm hero-title">
                      Invest Smart & Safe with Titiksha Wealth
                    </div>

                    <div className="fs-16 mr_reg mt-4 ">
                      At Titiksha Wealth, we use smart strategies to grow your wealth. Our focus on market timing and momentum-based investing ensures that your investments are handled with care and expertise.
                    </div>
                    <div className="mt-4">

                      <button
                        className="fs-16 bg-color1 px-4 py-2 border-0 rounded-2 "
                      >
                        <a href="#aboutSection" className="colorWhite mr_med text-decoration-none" onClick={handleClick}>
                          Learn More
                        </a>
                      </button>

                    </div>
                  </div>
                </div>
                <button
                  className=" position-absolute bg-transparent border-0"
                  style={{ bottom: "3rem" }}
                >
                  <a href="#aboutSection" onClick={handleClick}>
                    <img
                      src={toAbsoluteUrl(
                        "/media/images/HeroSection/arrow-down.svg"
                      )}
                      alt="down-arrow"
                    />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
