import React from "react";
interface ServiceCardProps {
  img: string;
  title: string;
  text: string;
}
const ServiceCard: React.FC<ServiceCardProps> = ({ img, title, text }) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 px-lg-4 p-md-5">
        <div className="card border-0 text-center">
          <div className="card-body p-0">
            <div className="mb-lg-5 mb-4">
              <img
                src={img}
                className=""
                alt="..."
              />
            </div>
            <h5 className="card-title rw_reg fs-24 color1 mb-lg-4">{title}</h5>
            <p className="card-text fs-16 mr_reg color20">{text}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
