import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssestHelpers';
import AboutCard from '../../../Common/AboutCard/AboutCard';
import './style.css';
import SupportForm from '../../../Common/SupportForm/SupportForm';
import { useNavigate } from 'react-router-dom';
const About = () => {
	const navigate = useNavigate();
	const cardData = [
		{
			img: toAbsoluteUrl('/media/images/AboutSection/index-fund.svg'),
			title: 'Techno Funda Analysis',
			text: "Invest smarter with a dual approach—technical analysis answers the 'when & where,' while fundamental analysis answers the 'what' of your investments.",
		},
		{
			img: toAbsoluteUrl('/media/images/AboutSection/bonds.svg'),
			title: 'Quantitative Models',
			text: 'Utilize advanced quantitative models to identify assets with strong momentum, ensuring your portfolio is always on the cutting edge.',
		},
		{
			img: toAbsoluteUrl('/media/images/AboutSection/mutual-fund.svg'),
			title: 'Systematic Approach',
			text: 'Remove emotional bias from your investment decisions with our rule-based strategy that keeps you focused on long-term gains.',
		},
		{
			img: toAbsoluteUrl('/media/images/AboutSection/equity.svg'),
			title: 'High Growth Potential',
			text: 'Designed for investors seeking capital appreciation, Impulse Alpha thrives in high volatility and market fluctuations.',
		},
	];

	return (
		<>
			<section id="aboutSection">
				<div className="container">
					<div className="row">
						<div className="col  py-5">
							<div className="row">
								<div className="col  py-lg-5">
									<div className="px-1 px-lg-0">
										<div className="row">
											<div className="align-content-around col-xl-8 col-lg-7">
												<div className="color6 fs-16 mr_reg">Pioneers in Investing</div>
												<div className="color3 fs-48 rw_reg lh-sm my-2 about-title">
													Unleash Market Potential with Titiksha Wealth
												</div>
												<div className="color3 fs-16 mr_reg my-3 mb-4">
													Streamline Your Investments with Titiksha: One Portfolio, Endless
													Possibilities. Simplify your investment journey by consolidating all your
													assets into a single, seamless platform. Experience the convenience of
													managing your diverse investments effortlessly.
												</div>
												<button
													className="fs-16 mr_reg bg-color1 px-4 py-2 border-0 colorWhite rounded-1 mt-2"
													onClick={() => {
														navigate('/product');
													}}>
													Learn More
												</button>
											</div>
											<div className="col-xl-4 col-lg-5">
												<div className="d-flex justify-content-lg-end justify-content-center my-lg-0 my-4">
													<img
														className="desktop-img"
														src={toAbsoluteUrl('/media/images/AboutSection/gujarat-map.svg')}
														alt=""
													/>
													<img
														className="mobile-img img-fluid"
														src={toAbsoluteUrl('/media/images/AboutSection/gujarat-map-mobile.svg')}
														alt=""
													/>
												</div>
											</div>
										</div>
										<hr />
										<div className="row text-lg-start text-center text-md-start">
											{cardData.map((card, index) => (
												<AboutCard key={index} img={card.img} title={card.title} text={card.text} />
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default About;
