import React from 'react';
import './style.css';

interface StockDataProps {
	color: string;
	label: string;
}

const StockData: React.FC<StockDataProps> = ({ color, label }) => {
	return (
		<>
			<span className="stock-data-container">
				<div className="stock-data-circle" style={{ backgroundColor: color }}></div>
				<div className='mr_reg'>{label}</div>
			</span>
		</>
	);
};

export default StockData;
