import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssestHelpers';
import SupportForm from '../../../Common/SupportForm/SupportForm';
import './style.css';
import { useNavigate } from 'react-router-dom';
const Product = () => {
	const navigate = useNavigate();
	return (
		<>
			<section id="mainProductSection">
				<div className="container">
					<div className="row">
						<div className="col  py-5">
							<div className="row">
								<div className="col  py-lg-5">
									<div className="px-1 px-lg-0">
										<div className="row">
											<div className="align-content-around col-lg-6">
												<div className="color6 fs-20 mr_reg">Our Flagship Product</div>
												<div className="color3 fs-48 rw_bold lh-sm my-2 about-title">Impulse α</div>
												<div className="color3 fs-16 mr_reg my-3 mb-4 pe-lg-4">
													Impulse Alpha is our flagship investment strategy designed to capture
													market trends and capitalize on momentum. Rooted in rigorous technical and
													fundamental analysis, this product offers a systematic approach to
													achieving high capital appreciation while navigating market volatility.
												</div>
												<button
													className="fs-16 mr_reg bg-color1 px-4 py-2 border-0 colorWhite rounded-1 mt-lg-2 my-4"
													onClick={() => {
														navigate('/product');
													}}>
													Learn More
												</button>
											</div>
											<div className="col-lg-6">
												<div className="d-flex justify-content-lg-end justify-content-center my-lg-0 my-4">
													<img
														className="img-fluid w-100"
														src={toAbsoluteUrl('/media/images/Product/product.svg')}
														alt=""
													/>
													{/* <img
                            className="mobile-img"
                            src={toAbsoluteUrl(
                              "/media/images/AboutSection/gujarat-map-mobile.svg"
                            )}
                            alt=""
                          /> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Product;
