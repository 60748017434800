import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname === '/product' || pathname === '/support' || pathname === '/disclosure') {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 200);
		}
	}, [pathname]);

	return null;
};

export default ScrollToTop;
